import AbunTable from "components/AbunTable/AbunTable";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import {ArticleTitle} from "./Articles";
import { pageURL } from "../../pages/routes";
import { Link } from "react-router-dom";
import "./integration.scss"

export interface AutomationProject {
    projectName: string;
    projectId: string;
    automationState: string;
    selectedKeywordProjectName: string;
    selectedKeywordProjectId: string;
    trafficRangeMin: number;
    trafficRangeMax: number;
    articlesCount: number;
    frequency: string;
    integration: string;
    publishState: string;
    publishDays: string[]; 
    publishTime: string; 
    publishOnlyGeneratedArticles: boolean;
    dateCreated: string; 
    integration_unique_id: string; 
    publishedArticlesCount: number;
    publishedArticleSet: ArticleTitle[];
}

interface AutomationProjectsProps {
    automation_projects: AutomationProject[]; // Array of AutomationProject
    total: number;
    userId: number;
}

export default function AutomationProjects(props: AutomationProjectsProps){
    // ---------------------- NON STATE CONSTANTS ----------------------
	const pageSizes = [10, 50, 150, 250, 500];
    const userId = props.userId

    // ---------------------- TABLE COLUMN DEFS ----------------------
    const columnHelper = createColumnHelper<AutomationProject>();
	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: AutomationProject) => row.projectName, {
			id: 'projectName',
			header: "Automation Project",
			cell: props => {
                return (
                    <Link
                        to={{
                        pathname: pageURL['viewAutomations'],
                        }}
                        state={{
                            project: props.row.original,
                            userId: userId, 
                        }}
            
                    >
                        {props.row.original.projectName}
                    </Link>
                )
        },
        enableGlobalFilter: true,
		}),
		columnHelper.display({
			id: 'automationState',
			header: "State",
			cell: props => {
				return (					
						<span className={"ml-2"}>{props.row.original.automationState}</span>
				);
			},
			enableGlobalFilter: false,
		}),
        columnHelper.display({
			id: 'trafficRangeMax',
			header: "Traffic Range",
			cell: props => {
				return (
						<span className={"ml-2"}>{props.row.original.trafficRangeMax}</span>
				);
			},
			enableGlobalFilter: false,
		})
    ]
    return(
        <div className={"card w-100 mt-4"}>
                <div className={"card-header"}>
                        <p className={"card-header-title"}>Automation Projects</p>
                    </div>
                    <div className={"card-content"}>
				        <div className={"content"}>
                            <AbunTable tableContentName={"Automation Projects"}
                                    tableData={props.automation_projects}
                                    columnDefs={columnDefs}
                                    pageSizes={pageSizes}
                                    initialPageSize={pageSizes[0]}
                                    noDataText={"No Automation project data available."}
                                    searchboxPlaceholderText={"Search Automation projects..."}
                                />
                        </div>
                    </div>            
        </div>
    )
}