import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { pageURL } from "pages/routes";
import { redirect } from "react-router-dom";
import { clearTokens, renewAccessToken, retrieveTokens } from "./jwt";

function axiosInterceptorSuccessFn(response: AxiosResponse) {
	return response;
}

async function axiosInterceptorFailFn(error: AxiosError) {
	if (error.response?.status === 401) {
		const renewalSuccessful: boolean = await renewAccessToken();
		// Renew access token
		if (renewalSuccessful) {
			// send back the same error (status code 401) so that we can trigger the retry.
			return Promise.reject(error);
		} else {
			return Promise.reject("Access token renewal failed. Refresh token is most likely stale or missing.");
		}
	} else {
		// some other error
		return Promise.reject(`Request failed with status code ${error.response?.status}`);
	}
}

/**
 * Use this as retry function on all useQery and useMutation calls.
 */
export const retryFn = (failureCount: number, error: AxiosError) => {
	return error.response?.status === 401;
}

/**
 * Calls the authentication api endpoint to check if admin user is valid. If not, redirects to login page.
 */
export const authenticate = async () => {
	const tokens = retrieveTokens();

	// If refresh token is already missing, clear out access token (if present) and redirect to login page.
	if (!tokens.refresh) {
		clearTokens();
		return redirect(pageURL['login'])
	}

	const axiosInstance = axios.create();
	const axiosConfig: AxiosRequestConfig = {
		method: "get",
		url: process.env.REACT_APP_DRF_DOMAIN + "/api/admin/authenticate/",
		headers: {
			'Authorization': 'Bearer ' + tokens.access
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	try {
		await axiosInstance(axiosConfig);
		return null;

	} catch (e) {
		// Non-200 status codes
		const axiosError = e as AxiosError;
		console.error(axiosError.response?.status);

		if (axiosError.response?.status === 401) {
			// Access token expired. Create new one.
			const renewalSuccessful = await renewAccessToken();

			if (renewalSuccessful) {
				// Success. Continue to requested page.
				return null;

			} else {
				// Renewal failed. Redirect to login page.
				throw new Error(`Token renewal failed with status code ${axiosError.response?.status}.`);
			}

		} else {
			// Server Error?
			throw new Error(`Authentication Request failed with status code ${axiosError.response?.status}`);
		}
	}
}

/**
 * POST reqeust to log out admin user. Clears the tokens and returns a redirect to login page.
 */
export const adminLogout = async () => {
	const tokens = retrieveTokens();

	// If refresh token is already missing, clear out access token (if present) and redirect to login page.
	if (!tokens.refresh) {
		clearTokens();
		return redirect("/login");
	}

	// Send request to logout endpoint to blacklist current tokens
	try {
		await axios({
			method: "post",
			url: process.env.REACT_APP_DRF_DOMAIN + "/api/admin/logout/",
			data: {
				refresh: tokens.refresh
			},
			responseType: "json"
		});
	} catch (err) {
		// Logout failed due to client or server error. Return to dashboard page
		console.error(err);
		return redirect(pageURL['dashboard'])
	}

	// Delete tokens and redirect to login page
	clearTokens();
	return redirect(pageURL['login']);
}

/**
 * Boilerplate code to fetch data from secure Abun DRF api endpoints.
 * Uses GET request. Returns Axios instance.
 *
 * @param apiPath - Abun DRF api endpoint path (ex: /api/get-some-data/). Starting forward slash is required.
 */
export async function authenticateAndFetchData(apiPath: string) {
	const tokens = retrieveTokens();
	const axiosInstance = axios.create();
	const axiosConfig: AxiosRequestConfig = {
		method: "get",
		url: process.env.REACT_APP_DRF_DOMAIN + apiPath,
		responseType: "json",
		headers: {
			'Authorization': 'Bearer ' + tokens.access
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

/**
 * Boilerplate code to authenticate and post data to Abun DRF api endpoints.
 *
 * @param apiPath - Abun DRF api endpoint path (ex: /api/get-some-data/). Starting forward slash is required.
 * @param data - json data to post.
 */
function authenticateAndPostData(apiPath: string, data: any) {
	const tokens = retrieveTokens();
	const axiosInstance = axios.create();
	const axiosConfig: AxiosRequestConfig = {
		method: "post",
		url: process.env.REACT_APP_DRF_DOMAIN + apiPath,
		responseType: "json",
		headers: {
			'Authorization': 'Bearer ' + tokens.access
		},
		data: data
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

// ================================================================================================
// ------------------------------- API QUERY AND MUTATION FUNCTIONS -------------------------------
// ================================================================================================

/**
 * POST reqeust for admin login.
 * @param loginData - email, password & admin secret
 */
export const adminLogin = (loginData: { email: string, password: string, secret: string }) => {
	const axiosInstance = axios.create();
	const axiosConfig: AxiosRequestConfig = {
		method: "post",
		url: process.env.REACT_APP_DRF_DOMAIN + "/api/admin/login/",
		responseType: "json",
		headers: {
			"Content-Type": "application/json",
		},
		data: {
			email: loginData.email,
			password: loginData.password,
			secret: loginData.secret
		}
	}
	return axiosInstance(axiosConfig);
}

export const adminSignup = (signupData: { username: string, email: string, password: string, secret: string }) => {
	const axiosInstance = axios.create();
	const axiosConfig: AxiosRequestConfig = {
		method: "post",
		url: process.env.REACT_APP_DRF_DOMAIN + "/api/admin/signup/",
		responseType: "json",
		data: {
			username: signupData.username,
			email: signupData.email,
			password: signupData.password,
			secret: signupData.secret
		}
	}
	return axiosInstance(axiosConfig);
}

/**
 * GET request to authenticate & fetch dashboard data
 */
export const getDashboardData = () => {
	return authenticateAndFetchData("/api/admin/dashboard/");
}

/**
 * GET request to authenticate and fetch all admin accounts data.
 */
export const getAllAdminData = () => {
	return authenticateAndFetchData("/api/admin/all-admins/");
}

/**
 * GET request to authenticate and fetch all user data.
 */
export const getAllUserData = (allUsers: boolean = false) => {
	return authenticateAndFetchData(`/api/admin/all-users/?allUsers=${allUsers}`);
}

/**
 * GET request to authenticate and fetch all user data.
 */
export const getArticleLogs = () => {
	return authenticateAndFetchData("/api/admin/get-article-logs/");
}

/**
 * GET request to authenticate and fetch user's data based on user id.
 */
export const getUserData = (userId: string) => {
	return authenticateAndFetchData("/api/admin/get-user-data?userId=" + userId);
}

/**
 * GET request to authenticate and fetch article content based on article UID.
 */
export const getArticleContent = (articleUID: string) => {
	return authenticateAndFetchData("/api/admin/get-article-content?articleUID=" + articleUID);
}
export const getGlossaryContent = (keywordHash: string) => {
	return authenticateAndFetchData("/api/admin/get-glossary-content?keyword_hash=" + keywordHash);
}

/**
 * GET request to authenticate and fetch keywords based on keyword project ID.
 */
export const getKeywordProjectData = (projectID: string) => {
	return authenticateAndFetchData("/api/admin/get-keyword-project-data/?project_id=" + projectID);
}

/**
 * GET request to authenticate and fetch keywords based on guest ID.
 */
export const getGuestPostData = (GuestID: string) => {
	return authenticateAndFetchData("/api/admin/get-guest-post-finder/?id=" + GuestID);
}

/**
 * GET request to authenticate and fetch glossaries based on glossary project ID.
 */
export const getGlossaryProjectData = (projectID: string) => {
	return authenticateAndFetchData("/api/admin/get-glossary-project-data/?project_id=" + projectID);
}

// =====================================================================
// ---------------------- LOAD ARTICLE TITLES for KEYWORD QUERY ----------------------
// =====================================================================

export const loadArticleTitlesForKeywordQuery = (keywordHash: string) => {
	return authenticateAndFetchData("/api/admin/get-article-titles-for-keyword?keyword_hash=" + keywordHash);
}

export const downloadBlogDataAsCsv = (queryData: {
	projectId: string
}) => {
	return authenticateAndFetchData(`/api/frontend/get-blog-data?blogProjectId=${queryData.projectId}`)
}

/**
 * POST request to save user data. All fields are required even if unchanged.
 */
export const saveUserData = (updatedUserData: {
	user_id: number
	email_verified: boolean
	email_notifications: boolean
	country: string
	user_tz: string
	titles_generated: number
	total_titles_generated: number
	articles_generated: number
	total_articles_generated: number
}) => {
	return authenticateAndPostData("/api/admin/save-user-data/", updatedUserData);
}

/**
 * POST request to save user's website data. All fields are required even if unchanged.
 */
export const saveWebsiteData = (updatedWebsiteData: {
	user_id: number
	domain: string
	image_source: string
}) => {
	return authenticateAndPostData("/api/admin/save-website-data/", updatedWebsiteData);
}

/**
 * POST request to delete user's connected website from Abun.
 */
export const deleteWebsite = (websiteData: {
	user_id: number
	domain: string
}) => {
	return authenticateAndPostData("/api/admin/delete-website/", websiteData);
}

/**
 * GET request to fetch content plan data for website.
 */
export const getContentPlanData = (queryData: {
	userId: string,
	websiteId: string
}) => {
	return authenticateAndFetchData(
		`/api/admin/get-content-plan-data?user_id=${queryData.userId}&website_id=${queryData.websiteId}`
	);
}

/**
 * POST request to change content plan status to new value.
 */
export const changeContentPlanStatus = (postData: {
	user_id: number,
	website_id: number,
	new_status: "done" | "processing" | "failed"
}) => {
	return authenticateAndPostData("/api/admin/change-content-plan-status/", postData);
}

/**
 * POST request to retry failed content plan generation.
 */
export const retryFailedContentPlan = (postData: {
	user_id: number,
	website_id: number,
}) => {
	return authenticateAndPostData("/api/admin/retry-content-plan/", postData);
}

/**
 * GET request to fetch task details for given website id and job id (task id).
 * @param queryData
 */
export const getTaskDetails = (queryData: {
	userId: number | string
	jobId: string
}) => {
	return authenticateAndFetchData(
		`/api/admin/get-task-details?user_id=${queryData.userId}&job_id=${queryData.jobId}`
	);
}

/**
 * GET request to fetch all ignored competitors domains.
 */
export const getIgnoredCompetitorDomains = () => {
	return authenticateAndFetchData("/api/admin/get-ignored-competitor_domains/");
}

/**
 * POST request to add a list of competitor domains to ignore.
 */
export const addCompetitorDomainsToIgnore = (domains: string[]) => {
	return authenticateAndPostData("/api/admin/add-competitor-domains-to-ignore/", { domains: domains });
}

/**
 * POST request to remove ignored competitor domains.
 */
export const removeIgnoredCompetitorDomains = (domains: string[]) => {
	return authenticateAndPostData("/api/admin/remove-ignored-competitor-domains/", { domains: domains });
}

/**
 * GET request to authenticate & fetch Mini Tools Stat
 */
export const getMiniToolsData = () => {
	return authenticateAndFetchData("/api/admin/get-mini-tools-data/");
}

/**
 * GET request to fetch all block domains.
 */
export const getBlockDomains = () => {
	return authenticateAndFetchData("/api/admin/get-block-domains/");
}

/**
 * GET request to fetch all block websites Keywords.
 */
export const getBlockWebsiteKeywords = () => {
	return authenticateAndFetchData("/api/admin/get-block-website-keywords/");
}

/**
 * GET request to fetch all block connect websites Keywords.
 */
export const getBlockConnectWebsiteKeywords = () => {
	return authenticateAndFetchData("/api/admin/get-block-connect-website-keywords/");
}

/**
 * POST request to add a list of domains to block.
 */
export const addBlockDomains = (domains: string[]) => {
	return authenticateAndPostData("/api/admin/add-block-domains/", { domains: domains });
}

/**
 * POST request to add a list of websites keywords to block.
 */
export const addBlockWebsiteKeywords = (data: {
	keywords: string[],
	connect_website_keywords?: boolean
}) => {
	return authenticateAndPostData("/api/admin/add-block-website-keywords/", {
		keywords: data.keywords, connect_website_keywords: data.connect_website_keywords || false
	});
}

/**
 * POST request to remove block domains.
 */
export const removeBlockDomains = (domains: string[]) => {
	return authenticateAndPostData("/api/admin/remove-block-domains/", { domains: domains });
}

/**
 * POST request to remove block website keywords.
 */
export const removeBlockWebsiteKeywords = (data: {
	keywords: string[], connect_website_keywords?: boolean
}) => {
	return authenticateAndPostData("/api/admin/remove-block-website-keywords/", {
		keywords: data.keywords,
		connect_website_keywords: data.connect_website_keywords || false
	});
}

/**
 * POST request to update article data.
 */
export const updateArticle = (postData: {
	article_uid: string
	action: "mark_failed" | "delete" | "regenerate"
}) => {
	return authenticateAndPostData("/api/admin/update-article/", postData);
}

/**
 * GET request to fetch all backlinks.
 */
export const getBackLinks = () => {
	return authenticateAndFetchData("/api/admin/get-backlink/");
}

/**
 * POST request to add a backlink csv file.
 */
export const addBackLinks = (data: {
	csv_file: File,
}) => {
	const formData = new FormData();
    formData.append('csv_file', data.csv_file);

	return authenticateAndPostData("/api/admin/add-backlink/", formData);
}

/**
 * POST request to remove backlinks.
 */
export const removeBackLinks = (data: {
	names: string[]
}) => {
	return authenticateAndPostData("/api/admin/remove-backlink/", {
		names: data.names,
	});
}

/**
 * POST request to toggle backlinks visibility.
 */
export const toggleBackLinkVisibility = (data: {
	name: string
}) => {
	return authenticateAndPostData("/api/admin/toggle-backlink-visibility/", {
		name: data.name,
	});
}


/**
 * GET request to fetch all auto Coupons.
 */
export const getCoupons = () => {
	return authenticateAndFetchData("/api/admin/get-coupons/");
}

/**
 * POST request to add a backlink csv file.
 */
export const addCoupon = (data: {
	name: string,
	couponId: string
}) => {
	const formData = new FormData();
    formData.append('name', data.name);
    formData.append('couponId', data.couponId);

	return authenticateAndPostData("/api/admin/add-coupon/", formData);
}

/**
 * POST request to remove backlinks.
 */
export const removeCoupons = (data: {
	couponIds: string[]
}) => {
	return authenticateAndPostData("/api/admin/remove-coupons/", {
		couponIds: data.couponIds,
	});
}

/**
 * POST request to toggle backlinks visibility.
 */
export const enableCoupon = (data: {
	couponId: string
}) => {
	return authenticateAndPostData("/api/admin/enable-coupon/", {
		couponId: data.couponId,
	});
}

/**
 * GET request to fetch user integrations.
 */
export const getUserIntegrations = (
	userId: string
) => {
	return authenticateAndFetchData(
		`/api/admin/get-user-integrations?user_id=${userId}`
	);
}

/**
 * POST request to add or update integration configuration.
 */
export const removeIntegrationMutation = (data: {
	user_id: string,
	integration_type: string,
	integration_unique_id: string
}) => {
	return authenticateAndPostData("/api/admin/remove-user-integrations/", {
		user_id: data.user_id,
		integration_type: data.integration_type,
		integration_unique_id: data.integration_unique_id
	});
}
