import "./ViewGlossaryContent.scss"

import { useState } from "react";

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getGlossaryContent, retryFn } from "utils/api";
import PageLoading from "components/PageLoading/PageLoading";
import Markdown from 'markdown-to-jsx';
import PageLoadingError from "components/PageLoadingError/PageLoadingError";

export default function ViewGlossaryContent() {
	// --------------------- REACT ROUTER PARAMS ---------------------
	let { keywordHash } = useParams();

	// ------------------- STATES -------------------
	const [
		glossaryTitle,
		setGlossaryTitle
	] = useState("");

	const [
		glossaryContent,
		setGlossaryContent
	] = useState("");


	// --------------------- QUERY ---------------------
	const {
		isLoading,
		isError,
		error,
		data
	} = useQuery({
		queryKey: ['getGlossaryContent', keywordHash ],
		queryFn: () => getGlossaryContent(keywordHash!),
		refetchOnWindowFocus: false,
		retry: retryFn
	})

	// ------------------- FUNCTIONS -------------------
	function renderMainContent() {
			return (
				<div className={"article-editor-page-editor-container content"} data-color-mode="light">
					<h1>{glossaryTitle}</h1>
					{glossaryContent ? <Markdown>{glossaryContent}</Markdown> : <><h4 className="has-text-centered mt-6 is-size-3">Glossary Content Not Found</h4></>}
				</div>
			)
	}

	// --------------------- EFFECTS ---------------------
	useEffect(() => {
		if (data) {
			setGlossaryTitle(data['data']['term']);
			setGlossaryContent(data['data']['content']);
		}
	}, [data]);

	if (isLoading) return <PageLoading />;

	if (isError) return <PageLoadingError message={error as unknown as string} />;

	return (
		<>
			<div className={"card mt-4 w-100"}>
				<div className={"card-content"}>
					<div className={"content"}>
						{renderMainContent()}
					</div>
				</div>
			</div>
		</>
	)
}