import "./ViewGlossary.scss";
import { useEffect, useState } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useParams } from "react-router-dom";

import Input from "components/Input/Input";
import Table from "components/Table/Table";
import AbunButton from "components/AbunButton/AbunButton";
import { useQuery } from "@tanstack/react-query";
import { getGlossaryProjectData, retryFn } from "../../utils/api";
import PageLoading from "../../components/PageLoading/PageLoading";
import PageLoadingError from "../../components/PageLoadingError/PageLoadingError";
import { NavLink } from "react-router-dom";

// Adjusted interface based on API response
interface GlossaryTerm {
  term: string;
  keyword_hash?: string;
  is_generated?: boolean;
  is_processing?: boolean;
  is_archived?: boolean;
  posted_to?: string;
  feedback?: string;
  glossary_link?: string;
}

export default function ViewGlossary() {
  // --------------------- REACT ROUTER PARAMS ---------------------
  let { projectID } = useParams();

  // ---------------------- NON STATE CONSTANTS ----------------------
  const pageSizes = [100, 500, 1000];

  // ---------------------- STATES ----------------------
  const [glossariesData, setGlossaryData] = useState<GlossaryTerm[]>([]);
  const [searchBoxFilter, setSearchBoxFilter] = useState("");

  // ---------------------- QUERIES ----------------------
  const { isFetching, isError, error, data } = useQuery({
    queryKey: ["getGlossaryProjectData", projectID],
    queryFn: () => getGlossaryProjectData(projectID!),
    refetchOnWindowFocus: false,
    retry: retryFn,
  });

  // ---------------------- EFFECTS ----------------------
  useEffect(() => {
    if (data) {
      // Ensure null or undefined values are handled before setting the state
      const filteredGlossaries = (data["data"]["glossary_terms"] || []).map((term: GlossaryTerm) => ({
        ...term,
        term: term.term || "Unknown",
        keyword_hash: term.keyword_hash || "N/A",
        is_generated: term.is_generated ?? false,
        is_processing: term.is_processing ?? false,
        is_archived: term.is_archived ?? false,
        posted_to: term.posted_to || "N/A",
        feedback: term.feedback || "N/A",
        glossary_link: term.glossary_link || "",
      }));
      setGlossaryData(filteredGlossaries);
    }
  }, [data]);

  // ---------------------- TABLE COLUMN DEFS ----------------------
  const columnHelper = createColumnHelper<GlossaryTerm>();

  // Determine whether to show extra columns based on keyword_hash
  const showFullColumns = glossariesData.some(term => term.keyword_hash !== "N/A");

  const columnDefs: ColumnDef<GlossaryTerm, any>[] = [
    columnHelper.accessor("term", {
      id: "glossaryTerm",
      header: "Glossary Term",
      // cell: ({ row }) => row.original.term,  
      cell: (props) => {
        if (props.row.original.keyword_hash === 'N/A') {
          return <span>{props.row.original.term}</span>;  // Render term as plain text
        }
        return (
          <NavLink to={`/view-glossary-content/${props.row.original.keyword_hash}`}>
            {props.row.original.term}
          </NavLink>
        );
      },
      enableGlobalFilter: true,
    }),
    columnHelper.accessor("keyword_hash", {
      id: "keywordHash",
      header: "Keyword Hash",
      cell: ({ row }) => row.original.keyword_hash || "N/A",  // Directly access keyword_hash
      enableGlobalFilter: true,
    }),
    ...(showFullColumns
      ? [
        columnHelper.accessor("is_generated", {
          id: "isGenerated",
          header: "Generated",
          cell: ({ row }) => (row.original.is_generated ? "Yes" : "No"),  // Directly access is_generated
        }),
        columnHelper.accessor("is_processing", {
          id: "isProcessing",
          header: "Processing",
          cell: ({ row }) => (row.original.is_processing ? "Yes" : "No"),  // Directly access is_processing
        }),
        columnHelper.accessor("is_archived", {
          id: "isArchived",
          header: "Archived",
          cell: ({ row }) => (row.original.is_archived ? "Yes" : "No"),  // Directly access is_archived
        }),
        columnHelper.accessor("posted_to", {
          id: "postedTo",
          header: "Posted To",
          cell: ({ row }) => {
            const postedTo = row.original.posted_to || "N/A";  // Access posted_to directly
            const glossaryLink = row.original.glossary_link;

            if (postedTo === "wordpress" && !glossaryLink) {
              return "--";
            }

            return postedTo;
          },
        }),
        columnHelper.accessor("feedback", {
          id: "feedback",
          header: "Feedback",
          cell: ({ row }) => row.original.feedback === "no_feedback" ? "--" : row.original.feedback || "N/A",  // Access feedback directly
        }),
        columnHelper.accessor("glossary_link", {
          id: "glossaryLink",
          header: "Glossary Link",
          cell: ({ row }) => {
            const glossaryLink = row.original.glossary_link;
            const handleViewClick = () => {
              if (glossaryLink) {
                window.location.href = glossaryLink;
              }
            }
            return (
              <div>
                <AbunButton
                  type="primary"
                  className="is-small"
                  clickHandler={handleViewClick}
                  disabled={!glossaryLink}
                >
                  View
                </AbunButton>
              </div>
            );
          },
        }),
      ]
      : []),
  ];

  // ============================================================== 
  // ---------------------- MAIN RENDER CODE ---------------------- 
  // ============================================================== 
  if (isFetching) return <PageLoading />;

  if (isError) return <PageLoadingError message={error as unknown as string} />;

  return (
    <div className="glossary-container">
      <div className="table-controls">
        {/* -------------------------- SEARCH BOX -------------------------- */}
        <Input
          type="text"
          className="table-searchbox"
          placeholder="Search for Glossaries..."
          value={searchBoxFilter ?? ""}
          onChange={(e) => {
            setSearchBoxFilter(String(e.target.value));
          }}
        />
      </div>
      <Table
        tableContentName="Glossaries"
        tableData={glossariesData}
        columnDefs={columnDefs}
        pageSizes={pageSizes}
        initialPageSize={pageSizes[0]}
        noDataText="No Glossaries Found"
        searchBoxValue={searchBoxFilter}
      />
    </div>
  );
}
