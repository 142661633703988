import AbunTable from "components/AbunTable/AbunTable";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { NavLink } from "react-router-dom";

interface GlossaryProjects {
  projectName: string;
  glossaryWord: string;
  glossaryWords: string[];
  count: number;
  projectId: string;
}

interface GlossaryProjectsProps {
  glossaryProjects: GlossaryProjects[];
}

export default function GlossaryProjects(props: GlossaryProjectsProps) {
  // ---------------------- NON STATE CONSTANTS ----------------------
  const pageSizes = [10, 50, 150, 250, 500];

  // ---------------------- TABLE COLUMN DEFS ----------------------
  const columnHelper = createColumnHelper<GlossaryProjects>();
  const columnDefs: ColumnDef<any, any>[] = [
    columnHelper.accessor((row: GlossaryProjects) => row.projectName, {
      id: "projectName",
      header: "Glossary Project",
      cell: (props) => {
        return (
          <NavLink to={`/view-glossary/${props.row.original.projectId}`}>
            {props.row.original.projectName}
          </NavLink>
        );
      },
      enableGlobalFilter: true,
    }),
    columnHelper.accessor("projectId", {
      id: "projectId",
      header: "projectId",
      cell: (props) => <span>{props.row.original.projectId}</span>,
      enableGlobalFilter: true,
    }),
    columnHelper.accessor("count", {
      id: "count",
      header: "Glossary Count",
      cell: (props) => <span>{props.row.original.count}</span>,
      enableGlobalFilter: false,
    }),
  ];

  return (
    <>
      <AbunTable
        tableContentName={"Glossary Projects"}
        tableData={props.glossaryProjects}
        columnDefs={columnDefs}
        pageSizes={pageSizes}
        initialPageSize={pageSizes[0]}
        noDataText={"No glossary projects available."}
        searchboxPlaceholderText={"Search glossary projects..."}
      />
    </>
  );
}
