import { redirect, RouteObject } from "react-router-dom";
import LoggedOutLayout from "layouts/LoggedOutLayout";
import Login from "pages/Auth/Login/Login";
import Signup from "pages/Auth/Signup/Signup";
import LoggedInLayout from "layouts/LoggedInLayout";
import Dashboard from "pages/Dashboard/Dashboard";
import { adminLogout, authenticate } from "../utils/api";
import Error from "./Error/Error";
import { retrieveTokens } from "../utils/jwt";
import PageNotFound from "./404/404";
import Settings from "./Settings/Settings";
import AllUsers from "./AllUsers/AllUsers";
import ExportUsers from "./ExportUsers/ExportUsers"
import ManageUser from "./ManageUser/ManageUser";
import Admins from "./Admins/Admins";
import WebsiteContentPlan from "./WebsiteContentPlan/WebsiteContentPlan";
import TaskLog from "./TaskLog/TaskLog";
import IgnoredCompetitors from "./IgnoredCompetitorDomains/IgnoredCompetitorDomains";
import BlockDomains from "./BlockedDomains/blockedDomains";
import BlockWebsites from "./BlockWebsites/BlockWebsites";
import BlockKeywords from "./BlockKeywords/BlockKeywords";
import ViewArticle from "./ViewArticle/ViewArticle";
import ViewKeywords from "./ViewKeywords/ViewKeywords"
import ViewTitles from "./ViewTitles/ViewTitles"
import ViewBlog from "./ManageUser/ViewBlog"
import BackLinks from "./Backlinks/BackLinks"
import AutoCoupons from "./AutoCoupons/AutoCoupons"
import ArticleLogs from "./ArticleLogs/ArticleLogs"
import ViewAutomations from "./ViewAutomation/ViewAutomations"
import ViewGuestPostFinder from "./ViewGuestPostFinder/ViewGuestPostFinder";
import ViewGlossary from "./ViewGlossary/ViewGlossary";
import ViewGlossaryContent from "./ViewGlossaryContent/ViewGlossaryContent";

export const pageURL = {
	login: "/login",
	signup: "/signup",
	logout: "/logout",
	dashboard: "/",
	allUsers: "/all-users",
	admins: "/admins",
	manageUser: "/all-users/:userId",
	websiteContentPlan: "/all-users/:userId/content-plan/:websiteId",
	taskLogs: "/all-users/:userId/task-log/:jobId",
	settings: "/settings",
	ignoredCompetitors: "/ignored-competitors",
	blockedDomains: "/blocked-domains",
	blockedWebsites: "/blocked-websites",
	blockedWebsiteKeywords: "/blocked-website-keywords",
	backlinks: "/backlinks",
	autoCoupons: "/auto-coupons",
	viewArticle: "/view-article/:articleUID",
	viewKeywords: "/view-keywords/:projectID",
	viewGlossary: "/view-glossary/:projectID",
	viewGlossaryContent: "/view-glossary-content/:keywordHash",
	viewTitles: "/view-titles/:keywordHash",
	exportUsers: "/export-users",
	articleLogs: "/logs",
	viewAutomations: "/view-automations",
	viewGuestPostFinder: "/view-guest-post-finder/:GuestID",
	viewBlogUrl: "/blog-urls-table/:blogProjectId/:blogProjectName/:userId",
}

/**
 * Redirects users to dashboard page if already logged in.
 */
function authPageRedirections() {
	const tokens = retrieveTokens();
	if (tokens.refresh && tokens.access) {
		return redirect(pageURL['dashboard']);
	} else {
		return null;
	}
}


const routes: RouteObject[] = [
	// ---------------- LOGGED OUT PAGES ----------------
	{
		element: <LoggedOutLayout />,
		children: [
			{
				path: pageURL['login'],
				element: <Login />,
				loader: authPageRedirections,
			},
			{
				path: pageURL['signup'],
				element: <Signup />,
				loader: authPageRedirections,
			},
		]
	},
	// ---------------- LOGGED IN PAGES ----------------
	{
		element: <LoggedInLayout />,
		children: [
			{
				path: pageURL['dashboard'],
				element: <Dashboard />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['allUsers'],
				element: <AllUsers />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['admins'],
				element: <Admins />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['manageUser'],
				element: <ManageUser />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['websiteContentPlan'],
				element: <WebsiteContentPlan />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['taskLogs'],
				element: <TaskLog />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['settings'],
				element: <Settings />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['exportUsers'],
				element: <ExportUsers />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['articleLogs'],
				element: <ArticleLogs />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['ignoredCompetitors'],
				element: <IgnoredCompetitors />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['blockedDomains'],
				element: <BlockDomains />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['blockedWebsites'],
				element: <BlockWebsites />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['blockedWebsiteKeywords'],
				element: <BlockKeywords />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['backlinks'],
				element: <BackLinks />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['autoCoupons'],
				element: <AutoCoupons />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['viewArticle'],
				element: <ViewArticle />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['viewKeywords'],
				element: <ViewKeywords />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['viewGlossary'],
				element: <ViewGlossary />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['viewGlossaryContent'],
				element: <ViewGlossaryContent />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['viewTitles'],
				element: <ViewTitles />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['viewAutomations'],
				element: <ViewAutomations />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['viewGuestPostFinder'],
				element: <ViewGuestPostFinder />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
			{
				path: pageURL['viewBlogUrl'],
				element: <ViewBlog />,
				errorElement: <Error />,
				loader: async () => {
					return await authenticate();
				}
			},
		]
	},
	// ---------------- MISCELLANEOUS ----------------
	{
		path: pageURL['logout'],
		loader: async () => {
			return await adminLogout();
		}
	},
	// ---------------- BAD URLs (404) ----------------
	{
		path: "*",
		element: <PageNotFound />
	}
]

export default routes;
