import "./ViewGuestPostFinder.scss";
import { useEffect, useState } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useParams } from "react-router-dom";

import Input from "components/Input/Input";
import Table from "components/Table/Table";
import { useQuery } from "@tanstack/react-query";
import { getGuestPostData, retryFn } from "../../utils/api";
import PageLoading from "../../components/PageLoading/PageLoading";
import PageLoadingError from "../../components/PageLoadingError/PageLoadingError";

interface QueryResult {
    post_title: string;
    post_link: string;
    query: string;
    hypestat: {
        domain: string;
        organic_traffic: number;
        organic_keywords: number;
        domain_authority: number;
        total_backlinks: number;
        follow: number;
        no_follow: number;
        referring_domains: number;
    };
}

export default function ViewGuestPostFinder() {
    // --------------------- REACT ROUTER PARAMS ---------------------
    const { GuestID } = useParams();

    // ---------------------- NON STATE CONSTANTS ----------------------
    const pageSizes = [100, 500, 1000];

    // ---------------------- STATES ----------------------
    const [queries, setQueries] = useState<QueryResult[]>([]);
    const [searchBoxFilter, setSearchBoxFilter] = useState("");

    // ---------------------- QUERIES ----------------------
    const { isFetching, isError, error, data } = useQuery({
        queryKey: ["getGuestPostData", GuestID],
        queryFn: () => getGuestPostData(GuestID!),
        refetchOnWindowFocus: false,
        retry: retryFn,
    });

    // ---------------------- EFFECTS ----------------------
    useEffect(() => {
        if (data) {
            // Function to replace null/undefined with "N/A"
            const setDefaults = (item: QueryResult): QueryResult => ({
                post_title: item.post_title || "N/A",
                post_link: item.post_link || "N/A",
                query: item.query || "N/A",
                hypestat: {
                    domain: item.hypestat?.domain || "N/A",
                    organic_traffic: item.hypestat?.organic_traffic ?? "0",
                    organic_keywords: item.hypestat?.organic_keywords ?? "0",
                    domain_authority: item.hypestat?.domain_authority ?? "0",
                    total_backlinks: item.hypestat?.total_backlinks ?? "0",
                    follow: item.hypestat?.follow ?? "0",
                    no_follow: item.hypestat?.no_follow ?? "0",
                    referring_domains: item.hypestat?.referring_domains ?? "0",
                },
            });

            // Transform data
            const transformedQueries = data['data']['queries'].map(setDefaults);

            setQueries(transformedQueries);
        }
    }, [data]);

    // ---------------------- FORMAT NUMBERS ----------------------
    const formatNumber = (num: number): string => {
        if (num >= 1_000_000) return `${(num / 1_000_000).toFixed(1)}M`;
        if (num >= 1_000) return `${(num / 1_000).toFixed(1)}k`;
        return num.toString();
    };

    // ---------------------- TABLE COLUMN DEFS ----------------------
    const columnHelper = createColumnHelper<QueryResult>();
    const columnDefs: ColumnDef<QueryResult, any>[] = [
        columnHelper.accessor("post_title", {
            header: "URL",
            cell: (info) => <a href={info.row.original.post_link} target="_blank" rel="noopener noreferrer">{info.getValue()}</a>,
        }),
        columnHelper.accessor((row) => row.hypestat.domain, {
            id: "domain",
            header: "Domain",
            cell: (info) => <a href={`https://${info.row.original.hypestat.domain}`} target="_blank" rel="noopener noreferrer">{info.getValue()}</a>,
        }),
        columnHelper.accessor((row) => row.hypestat.domain_authority, {
            id: "domain_authority",
            header: "Domain Authority",
            cell: (info) => formatNumber(info.getValue()),
        }),
        columnHelper.accessor((row) => row.hypestat.organic_traffic, {
            id: "organic_traffic",
            header: "Organic Traffic",
            cell: (info) => formatNumber(info.getValue()),
        }),
        columnHelper.accessor((row) => row.hypestat.organic_keywords, {
            id: "organic_keywords",
            header: "Organic Keywords",
            cell: (info) => formatNumber(info.getValue()),
        }),
        columnHelper.accessor((row) => row.hypestat.follow, {
            id: "follow",
            header: "Follow",
            cell: (info) => formatNumber(info.getValue()),
        }),
        columnHelper.accessor((row) => row.hypestat.no_follow, {
            id: "no_follow",
            header: "No Follow",
            cell: (info) => formatNumber(info.getValue()),
        }),
        columnHelper.accessor((row) => row.hypestat.referring_domains, {
            id: "referring_domains",
            header: "Referring Domains",
            cell: (info) => formatNumber(info.getValue()),
        }),
        columnHelper.accessor((row) => row.hypestat.total_backlinks, {
            id: "total_backlinks",
            header: "Total Backlinks",
            cell: (info) => formatNumber(info.getValue()),
        }),
    ];

    // ==============================================================  
    // ---------------------- MAIN RENDER CODE ----------------------  
    // ==============================================================  
    if (isFetching) return <PageLoading />;

    if (isError) return <PageLoadingError message={error as unknown as string} />;

    return (
        <div className={"guest-container"}>
            <div className={"table-controls"}>
                {/* -------------------------- SEARCH BOX -------------------------- */}
                <Input
                    type={"text"}
                    className={"table-searchbox"}
                    placeholder={"Search for Queries..."}
                    value={searchBoxFilter ?? ""}
                    onChange={(e) => {
                        setSearchBoxFilter(String(e.target.value));
                    }}
                />
            </div>
            <Table
                tableContentName={"Backlinks"}
                tableData={queries}
                columnDefs={columnDefs}
                pageSizes={pageSizes}
                initialPageSize={pageSizes[0]}
                noDataText={"No Guest Post Data Found"}
                searchBoxValue={searchBoxFilter}
            />
        </div>
    );
}
