import AbunTable from "components/AbunTable/AbunTable";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import {NavLink} from "react-router-dom";
import "./integration.scss"

interface KeywordProjects {
	projectName: string
	totalKeywords: number
	totalTrafficVolume: number
	dateCreated: string
	projectId: string
	locationIsoCode: string
}

interface KeywordProjectsProps {
    keywordProjects: KeywordProjects[]
}

export default function KeywordProjects(props: KeywordProjectsProps){
    // ---------------------- NON STATE CONSTANTS ----------------------
	const pageSizes = [10, 50, 150, 250, 500];

    // ---------------------- TABLE COLUMN DEFS ----------------------
	const columnHelper = createColumnHelper<KeywordProjects>();
	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: KeywordProjects) => row.projectName, {
			id: 'projectName',
			header: "Keyword Project",
			cell: props => {
					return (
						<NavLink to={`/view-keywords/${props.row.original.projectId}`} >
							{props.row.original.projectName}
						</NavLink>
					)
            },
			enableGlobalFilter: true,
		}),
		columnHelper.display({
			id: 'totalKeywords',
			header: "Total Keywords",
			cell: props => {
				if (props.row.original.totalKeywords > 0) {
					return (
						<span>{props.row.original.totalKeywords}</span>
					);
				}
			},
			enableGlobalFilter: false,
		}),
		columnHelper.display({
			id: 'totalTrafficVolume',
			header: "Total Traffic Volume",
			cell: props => {
				if (props.row.original.totalTrafficVolume > 0) {
					// convert to k, m, b
					let totalTrafficVolume = "" + props.row.original.totalTrafficVolume;
					if (props.row.original.totalTrafficVolume > 999 && props.row.original.totalTrafficVolume < 1000000) {
						totalTrafficVolume = (props.row.original.totalTrafficVolume / 1000).toFixed(2) + "K";
					} else if (props.row.original.totalTrafficVolume > 999999 && props.row.original.totalTrafficVolume < 1000000000) {
						totalTrafficVolume = (props.row.original.totalTrafficVolume / 1000000).toFixed(2) + "M";
					} else if (props.row.original.totalTrafficVolume > 999999999) {
						totalTrafficVolume = (props.row.original.totalTrafficVolume / 1000000000).toFixed(2) + "B";
					} else {
						totalTrafficVolume = "" + props.row.original.totalTrafficVolume;
					}
					return (
						<span>{totalTrafficVolume}</span>
					);
				}
			},
			enableGlobalFilter: false,
		}),
	];


    return (
        <div className={"card w-100 mt-4"}>
                <div className={"card-header"}>
                        <p className={"card-header-title"}>Keyword Projects</p>
                    </div>
                    <div className={"card-content"}>
				        <div className={"content"}>
							<AbunTable tableContentName={"Keyword Projects"}
								tableData={props.keywordProjects}
								columnDefs={columnDefs}
								pageSizes={pageSizes}
								initialPageSize={pageSizes[0]}
								noDataText={"No keywords data available."}
								searchboxPlaceholderText={"Search keywords projects..."}
							/>
						</div>
					</div>			
        </div>
    )
}