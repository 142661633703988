import AbunTable from "components/AbunTable/AbunTable";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import {useState, useEffect} from "react";
import { BlogFinderTableProps } from "./BlogFinder";
import {downloadBlogDataAsCsv} from "utils/api";
import { useQuery } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import Card from "components/Card/Card";

type Params = {
    blogProjectId: string;
    blogProjectName: string;
    userId: string;
};

const ViewBlog = () => {
    const pageSizes = [5, 10, 15, 30, 50, 100, 500];
    const navigate = useNavigate()
    const { blogProjectId, blogProjectName, userId } = useParams<Params>();
    const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);
    const [tableData, setTableData] = useState<BlogFinderTableProps[]>([]);
    const [isCopied, setIsCopied] = useState<Boolean>(false);
    
    const validBlogProjectId = blogProjectId ?? "";

    const { data: csvData } = useQuery({
        queryKey: ['downloadBlogDataAsCsv', validBlogProjectId],
        queryFn: () => downloadBlogDataAsCsv({ projectId: validBlogProjectId }),
        enabled: !!blogProjectId, 
        refetchOnWindowFocus: false,
      });
    
    useEffect(() => {
        if (csvData && validBlogProjectId) {
            setTableData((csvData as any)['data']['data']);
            }
        }, [csvData]);

    const handleMouseEnter = (rowId: string) => {
        setHoveredRowId(rowId);
    };
    const handleMouseLeave = () => {
        setHoveredRowId(null);
    };

    const handleCopy = (email: string) => {
        navigator.clipboard.writeText(email);
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false)
        }, 2000);
    };
   
    const columnHelper = createColumnHelper<BlogFinderTableProps>();
    const columnDefs: ColumnDef<any, any>[] = ([
        columnHelper.accessor((row: BlogFinderTableProps) => row.authorName, {
            id: 'authorName',
            header:"Author Name",
            cell: props => {
                if(props.row.original.authorName){
                    return (
                        <span>{props.row.original.authorName}</span>
                    )}},
                }),
                columnHelper.display({
                    id: 'emailAddress',
                    header: "Email Address",
                    cell: props => {
                        const rowId = props.row.id;
        
                        if (props.row.original.emailAddress) {
                            return (
                                <div
                                    style={{ position: 'relative', display: 'inline-block' }}
                                    onClick={() => handleCopy(props.row.original.emailAddress)}
                                    onMouseEnter={() => handleMouseEnter(rowId)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <span style={{cursor: 'pointer'}}>{props.row.original.emailAddress}</span>
                                    {hoveredRowId === rowId && (
                                        <button
                                            onClick={() => handleCopy(props.row.original.emailAddress)}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '100%',
                                                transform: 'translate(0, -50%)',
                                                backgroundColor: '#ffffff',
                                                border: '1px solid #ccc',
                                                borderRadius: '5px',
                                                padding: '5px',
                                                cursor: 'pointer',
                                                zIndex: 10,
                                            }}
                                        >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#8fbffa" className="bi bi-copy" viewBox="0 0 16 16" id="Copy--Streamline-Bootstrap" height="16" width="16" style={{marginRight: '3px',}}><path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2H6a2 2 0 0 1 -2 -2zm2 -1a1 1 0 0 0 -1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1 -1V2a1 1 0 0 0 -1 -1zM2 5a1 1 0 0 0 -1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1 -1v-1h1v1a2 2 0 0 1 -2 2H2a2 2 0 0 1 -2 -2V6a2 2 0 0 1 2 -2h1v1z" stroke-width="1"></path></svg>
                                        {isCopied ? 'Copied!' : 'Copy'}
                                        </button>
                                    )}
                                </div>
                            );
                        }
                        return null;
                    },
                }),
            columnHelper.display({
                id: 'createdOn',
                header: "Created On",
                cell: props => {
                    if (props.row.original.createdOn) {                         
                        const date = new Date(props.row.original.createdOn);
                        return date.toLocaleString();
                    }
                },
            }),
            columnHelper.display({
                id: 'blogUrls',
                header: "Blog Urls",
                cell: props => {
                    if (props.row.original.blogUrl) {
                        return (
                            <span
                            onClick={() => {
                                window.open(props.row.original.blogUrl, '_blank');
                            }} style={{cursor: 'pointer'}}>
                                {props.row.original.blogUrl}
                            </span>                                
                        );
                    }
                },
            }),
        ])


    return (
        <>       
        <Card className={"mt-4 is-flex is-flex-direction-column container-content"}>
        <div className="ps-container">
                <div className={"seo-project-header"}>
                    <svg className={"back-btn seo-back"} onClick={() => navigate(`/all-users/${userId}`)} stroke="#bcbcbc" fill="#bcbcbc" width="28" height="24" viewBox="0 0 28 24">
                        <path d="M27.5 12H2M2 12L13 1M2 12L13 23" stroke="black" strokeOpacity="0.5" strokeWidth="2" />
                    </svg>
                </div>
                <h1 className="ps-header">Blog Urls: <span style={{color:"#3F77F8"}}>{blogProjectName}</span></h1>
                <div className="seo-form-container">
                    <>
                    <AbunTable tableContentName={"Blog Urls"}
                        tableData={tableData}
                        columnDefs={columnDefs}
                        pageSizes={pageSizes}
                        initialPageSize={pageSizes[4]}
                        noDataText={"No Blog found"}
                        searchboxPlaceholderText={"Search Blog..."}
                    />
                    </>
                 </div>
         </div>
        </Card>
        </>
    )
}

export default ViewBlog;