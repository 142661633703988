import "./ManageUser.scss";

import { useState } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Table from "components/Table/Table";
import Input from "components/Input/Input";
import { Link, useNavigate } from "react-router-dom";
export interface Query {
    id: number;
    query: string;
    limit: number;
    is_processing: boolean;
    created_at: string;
}

export default function GuestPostFinder(props: { guest_post_finder_queries: Array<Query> }) {
    // ---------------------- NON STATE CONSTANTS ----------------------
    const pageSizes = [10, 25, 50, 100];

    // ---------------------- STATES ----------------------
    const [searchBoxFilter, setSearchBoxFilter] = useState("");
    const navigate = useNavigate();

    // ---------------------- TABLE COLUMN DEFS ----------------------
    const columnHelper = createColumnHelper<Query>();
    const columnDefs: ColumnDef<any, any>[] = [
        columnHelper.accessor((row) => row.query, {
            id: "Topic",
            header: "Topic",
            cell: (info) =>
                <span onClick={() => navigate(`/view-guest-post-finder/${info.row.original.id}`)}
                    style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
                >
                    {info.getValue()}
                </span>,
            enableGlobalFilter: true,
        }),
        columnHelper.accessor((row) => row.limit, {
            id: "limit",
            header: "Limit",
            cell: (info) => info.getValue(),
            enableGlobalFilter: false,
        }),
        columnHelper.accessor((row) => row.created_at, {
            id: "created_on",
            header: "Created On",
            cell: (info) => {
                const date = new Date(info.getValue());
                return date.toLocaleString();
            },
            enableGlobalFilter: false,
        }),
    ];

    // ==============================================================  
    // ---------------------- MAIN RENDER CODE ----------------------
    // ==============================================================  
    return (
        <div className={"guest-post-finder-container"}>
            <div className={"table-controls"}>
                {/* -------------------------- SEARCH BOX -------------------------- */}
                <Input
                    type={"text"}
                    className={"table-searchbox"}
                    placeholder={"Search for Queries..."}
                    value={searchBoxFilter ?? ""}
                    onChange={(e) => {
                        setSearchBoxFilter(String(e.target.value));
                    }}
                />
            </div>
            <Table
                tableContentName={"Backlinks"}
                tableData={props.guest_post_finder_queries}
                columnDefs={columnDefs}
                pageSizes={pageSizes}
                initialPageSize={pageSizes[0]}
                noDataText={"No Queries Found"}
                searchBoxValue={searchBoxFilter}
            />
        </div>
    );
}
