import { useQuery } from "@tanstack/react-query";
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AbunTable from "../../components/AbunTable/AbunTable";
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import Drawer from "../../assets/images/Drawer-Download--Streamline-Ultimate.png";
import {downloadBlogDataAsCsv} from "utils/api";
import "./integration.scss"

export interface BlogFinderBulkTableProps{
    blogProjectName: string,
    blogProjectId: string,
    createdOn: string
}

interface BlogFinderBulkTableProjectProps{
    blogProject : BlogFinderBulkTableProps[];
    userId: number;
}

const BlogFinderProject = (props: BlogFinderBulkTableProjectProps) => {
    const navigate = useNavigate();
    const userId = props.userId
    const pageSizes = [5, 10, 15, 30, 50, 100, 500];
    const [downloadProjectId, setDownloadProjectId] = useState("");
    const [downloadProjectName, setDownloadProjectName] = useState("");

        const { data: csvData, isFetching: isFetchingCsv, error: csvError, refetch: refetchCsv } = useQuery({
          queryKey: ['downloadBlogDataAsCsv', downloadProjectId],
          queryFn: () => downloadBlogDataAsCsv({ projectId: downloadProjectId }),
          enabled: !!downloadProjectId, 
          refetchOnWindowFocus: false,
        });
      
        useEffect(() => {
          if (csvData && downloadProjectId) {
            const csv = convertToCSV(csvData?.data?.data || []);
            triggerDownload(csv);
            setDownloadProjectId('');
          }
        }, [csvData]);
      
        function convertToCSV(data) {
          const header = ['blogUrl', 'isValid', 'authorName', 'emailAddress', 'createdOn'];
          const rows = data.map((item) => [
            item.blogUrl || '',
            item.isValid !== undefined ? item.isValid : '',
            item.authorName || '',
            item.emailAddress || '',
            new Date(item.createdOn).toISOString() || '',
          ]);
          return [header.join(','), ...rows.map((row) => row.join(','))].join('\n');
        }
      
        function triggerDownload(csv) {
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          const link = document.createElement('a');
          link.download = `${downloadProjectName}_${downloadProjectId}.csv`;
          link.href = URL.createObjectURL(blob);
          link.click();
        }
      
        async function handleDownloadClick(blogProjectId,blogProjectName) {
          setDownloadProjectId(blogProjectId);
          setDownloadProjectName(blogProjectName)
          refetchCsv();
        }
    
    const blogcolumnHelper = createColumnHelper<BlogFinderBulkTableProps>();
    const columnDefs: ColumnDef<any, any>[] = ([
        blogcolumnHelper.accessor((row: BlogFinderBulkTableProps) => row.blogProjectName, {
            id: 'blogProjectName',
            header:"Blog Project",
            cell: props => {
                const handleClick = () => {
                    const { blogProjectId, blogProjectName } = props.row.original;
                    navigate(`/blog-urls-table/${blogProjectId}/${blogProjectName}/${userId}`);
                  };
                if (props.row.original.blogProjectName){
                    return (
                        <span onClick={handleClick} style={{ cursor: 'pointer' }}>{props.row.original.blogProjectName}</span>
                    )}
                }}),
                blogcolumnHelper.display({
                    id: 'createdOn',
                    header: "Created On",
                    cell: props => {
                        if (props.row.original.createdOn) {                         
                            const date = new Date(props.row.original.createdOn);
                            return date.toLocaleString();
                        }
                    },
                }),
                blogcolumnHelper.display({
                    id: 'action',
                    header: "Action",
                    cell: props => {
                        if (props.row.original.blogProjectName) {
                            return (
                                    <img 
                                        className="download-url-icon ml-4" 
                                        src={Drawer} 
                                        alt="upload-icon" 
                                        onClick={() => { handleDownloadClick(props.row.original.blogProjectId, props.row.original.blogProjectName) }} 
                                        style={{ cursor: 'pointer', height: '20px'}} 
                                    />
                            );
                        }
                    },
                }),
        ])


    return (
        <div className={"card w-100 mt-4"}>
                <div className={"card-header"}>
                        <p className={"card-header-title"}>Blog Projects</p>
                    </div>
                    <div className={"card-content"}>
				        <div className={"content"}>
                            <AbunTable tableContentName={"Blog Urls"}
                                tableData={props.blogProject}
                                columnDefs={columnDefs}
                                pageSizes={pageSizes}
                                initialPageSize={pageSizes[1]}
                                noDataText={"No keywords found"}
                                searchboxPlaceholderText={"Search keywords projects..."}
                            />
                        </div>
                    </div>                
            </div>
    )
}

export default BlogFinderProject;